import React, { useEffect, useState } from "react";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import Index from "./pages/index";
import "./index.css";
import useRedux from "hooks/useRedux";

function App() {
  const { appSelector } = useRedux();
  const isLoggedIn = appSelector((state) => state.auth.token);
  const pendingOrder = appSelector((state) => state.order.pendingOrder);

  const [modalType, setModalType] = useState<string>("");

  useEffect(() => {
    // enforce an  https on the client side
    if (window.location.href.substr(0, 5) !== "https") {
      window.location.href = window.location.href.replace("http", "https");
    }
    if (!isLoggedIn || Object.keys(pendingOrder).length === 0) {
      setModalType("LoginOption");
    }
  }, [isLoggedIn, pendingOrder]);

  return (
    <>
      <BrowserRouter>
        {" "}
        <Routes>
          <Route
            path="/order"
            element={
              <Index setModalType={setModalType} modalType={modalType} />
            }
          />

          <Route path="*" element={<Navigate to="/order" />} />
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
