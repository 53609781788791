import { checkoutReferenceRequest } from "types";
import { api } from "./index";

export const configEndpoint = api.injectEndpoints({
  endpoints: (build) => ({
    generateOTPWithCheckoutReference: build.mutation<
      any,
      checkoutReferenceRequest
    >({
      query: (body) => ({
        url: "/OTP/GenerateWithCheckoutReference",
        method: "POST",
        body,
      }),
    }),
    generateOTPWithCheckoutReference_: build.query<
      any,
      checkoutReferenceRequest
    >({
      query: (body) => ({
        url: "/OTP/GenerateWithCheckoutReference",
        method: "POST",
        body,
      }),
    }),
    generateWithOrderToken: build.mutation<any, checkoutReferenceRequest>({
      query: (body) => ({
        url: "/Auth/AccessToken/GenerateWithOrderToken",
        method: "POST",
        body,
      }),
    }),
    generateWithOtp: build.mutation<any, checkoutReferenceRequest>({
      query: (body) => ({
        url: "/Auth/AccessToken/GenerateWithOtp",
        method: "POST",
        body,
      }),
    }),
  }),
  overrideExisting: true,
});

export const {
  useGenerateOTPWithCheckoutReferenceMutation,
  useGenerateWithOrderTokenMutation,
  useGenerateOTPWithCheckoutReference_Query,
  useGenerateWithOtpMutation,
} = configEndpoint;
