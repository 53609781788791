export let orderType = window?.location?.search.split("=")[1] || "buy"; // Get this from the store

export const cryptoNetworks: { name: string; symbolUrl: string }[] = [
  {
    name: "BEP20",
    symbolUrl: "https://d20owwgrrz4drp.cloudfront.net/network-images/bep20.png",
  },
  {
    name: "ERC20",
    symbolUrl:
      "https://res.cloudinary.com/dyyj2hjf5/image/upload/v1668785496/untitled_zzns3h.png",
  },
  {
    name: "POLYGON",
    symbolUrl:
      "https://d20owwgrrz4drp.cloudfront.net/network-images/polygon.png",
  },
  {
    name: "TRON",
    symbolUrl:
      "https://res.cloudinary.com/dyyj2hjf5/image/upload/v1670234612/download-removebg-preview_pmpcrj.png",
  },
];

export const cryptoCurrencies: {
  name: string;
  symbolUrl: string;
  id?: string;
  currencyType?: string;
  currencySymbol?: string;
  network?: string;
  countryFlagUrl?: any;
  isActive?: boolean;
  currencySymbolText?: string;
}[] = [
  {
    id: "22f4f68b-e988-4e7f-9cd2-fc4171492b55",
    name: "BUSD",
    currencyType: "CRYPTO",
    currencySymbol: "BUSD",
    network: "BEP20",
    symbolUrl:
      "https://d2ey3ce1wbjugu.cloudfront.net/currencies/binance-usd-busd-logo.png",
    countryFlagUrl: null,
    currencySymbolText: "BUSD",
    isActive: true,
  },
  {
    id: "a0f5f367-977c-4166-8107-620a1b15c40a",
    name: "Wicrypt Network Token",
    currencyType: "CRYPTO",
    currencySymbol: "WNT",
    network: "POLYGON",
    symbolUrl: "https://d2ey3ce1wbjugu.cloudfront.net/currencies/wicrypt.webp",
    countryFlagUrl: null,
    currencySymbolText: "WNT",
    isActive: true,
  },
  {
    id: "6a5cbade-592b-42b4-a35f-a6cacd2e0a46",
    name: "Xend",
    currencyType: "CRYPTO",
    currencySymbol: "XEND",
    network: "BEP20",
    symbolUrl: "https://d2ey3ce1wbjugu.cloudfront.net/currencies/Xend.png",
    countryFlagUrl: null,
    currencySymbolText: "XEND",
    isActive: true,
  },
  {
    id: "7b7698bf-199e-4d2a-af30-23a19ac2d75e",
    name: "USD Coin",
    currencyType: "CRYPTO",
    currencySymbol: "USDC",
    network: "POLYGON",
    symbolUrl:
      "https://d2ey3ce1wbjugu.cloudfront.net/currencies/qkyd5vf6rjbl0d3tabxo.png",
    countryFlagUrl: null,
    currencySymbolText: "USDC",
    isActive: true,
  },
  {
    id: "ddb47913-c57e-441c-9dde-2ac9a3dd9fb0",
    name: "USD Tether",
    currencyType: "CRYPTO",
    currencySymbol: "USDT",
    network: "BEP20",
    symbolUrl:
      "https://d2ey3ce1wbjugu.cloudfront.net/currencies/USD_Tether.png",
    countryFlagUrl: null,
    currencySymbolText: "USDT",
    isActive: true,
  },
  {
    id: "5925b766-0fc4-4cc6-879e-73c38f5c9e2e",
    name: "USD Tether",
    currencyType: "CRYPTO",
    currencySymbol: "USDT",
    network: "POLYGON",
    symbolUrl:
      "https://d2ey3ce1wbjugu.cloudfront.net/currencies/USD_Tether.png",
    countryFlagUrl: null,
    currencySymbolText: "USDT",
    isActive: true,
  },
];
function removeDecimal(num: any) {
  if (num % 1 === 0) {
    return num;
  } else {
    var decimal = (num + "").split(".")[1];
    if (decimal && decimal.length >= 4 && decimal.substr(-4) === "0000") {
      return parseInt(num);
    } else {
      return num;
    }
  }
}

export const formatCurrency = (amount: string | number) => {
  let rounded = removeDecimal(amount);
  return Number(rounded).toLocaleString(undefined, {
    minimumFractionDigits: 2,
    maximumFractionDigits: 4,
  });
};

export const formatWalletAddress = (walletAddress: string) => {
  let hideNum = [];
  for (let i = 0; i < walletAddress.length; i++) {
    if (i > 3 && i < 8) {
      hideNum.push("*");
    } else {
      hideNum.push(walletAddress[i]);
    }
  }
  return hideNum.slice(0, 13).join("");
};

export const formatOrderRef = (orderRef: string) => {
  let hideNum = [];
  for (let i = 0; i < orderRef.length; i++) {
    if (i > 3 && i < 8) {
      hideNum.push("*");
    } else {
      hideNum.push(orderRef[i]);
    }
  }

  return hideNum.slice(0, 13).join("");
};

export const formatAcctNumber = (accountNumber: string) => {
  let hideNum = [];
  for (let i = 0; i < accountNumber.length; i++) {
    if (i < accountNumber.length - 4) {
      hideNum.push("X");
    } else {
      hideNum.push(accountNumber[i]);
    }
  }
  return hideNum.join("");
};

export const formatTime = (date: number) => {
  var seconds: number = Math.floor((new Date().valueOf() - date) / 1000);

  var interval = seconds / 31536000;

  if (interval > 1) {
    return Math.floor(interval) === 1
      ? Math.floor(interval) + " year ago"
      : Math.floor(interval) + " years ago";
  }
  interval = seconds / 2592000;
  if (interval > 1) {
    return Math.floor(interval) === 1
      ? Math.floor(interval) + " month ago"
      : Math.floor(interval) + " months ago";
  }
  interval = seconds / 86400;
  if (interval > 1) {
    return Math.floor(interval) === 1
      ? Math.floor(interval) + " day ago"
      : Math.floor(interval) + " days ago";
  }
  interval = seconds / 3600;
  if (interval > 1) {
    return Math.floor(interval) === 1
      ? Math.floor(interval) + " hour ago"
      : Math.floor(interval) + " hours ago";
  }
  interval = seconds / 60;
  if (interval > 1) {
    return Math.floor(interval) === 1
      ? Math.floor(interval) + " min ago"
      : Math.floor(interval) + " mins ago";
  }
  return Math.floor(seconds) + " secs ago";
};

export const capitalizeFirstLetter = (string: string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export const getP2pOrderStatus = (
  p2PorderStatus: string,
  orderType: string
) => {
  switch (p2PorderStatus) {
    case "Completed":
      return `${orderType} Order Completed`;
    case "Cancelled":
      return `${orderType} Order Canceled`;
    case "FundReceivedFromUser":
      return "Payment Confirmed";
    case "FundedProvider":
      return "Awaiting Payment Confirmation";
    default:
      break;
  }
};

export const getP2pOrderStatusMsg = (
  p2PorderStatus: string,
  networkType: string
) => {
  if (networkType !== "LOCAL") {
    switch (p2PorderStatus) {
      case "FundReceivedFromUser":
        return "We have received your deposit, and we will send fiat to your account within the next 30 minutes";
      case "FundedProvider":
        return "We will send fiat to your account as soon as we confirm your deposit.";

      default:
        break;
    }
  } else {
    switch (p2PorderStatus) {
      case "FundReceivedFromUser":
        return "We have received your receipts of fund, and we will send crypto to your wallet within the next 30 minutes.";
      case "FundedProvider":
        return "We will send crypto to your wallet as soon as we confirm the receipts of fund.";
      default:
        break;
    }
  }
};
