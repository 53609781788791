import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { User } from "types/auth";

// initialize userToken from local storage
const userToken = localStorage.getItem("token")
  ? JSON.parse(localStorage.getItem("token") || "")
  : null;

// initialize userInfo from local storage
const userInfo = localStorage.getItem("userInfo")
  ? JSON.parse(localStorage.getItem("userInfo") || "")
  : null;

// initialize checkoutReference from local storage
const ref = localStorage.getItem("checkoutReference")
  ? JSON.parse(localStorage.getItem("checkoutReference") || "")
  : null;

export type AuthState = {
  user: User | null;
  token: string | null;
  checkoutReference: string | null;
};

const slice = createSlice({
  name: "auth",
  initialState: {
    token: userToken,
    user: userInfo,
    checkoutReference: ref,
  } as AuthState,
  reducers: {
    setCredentials: (state, action: PayloadAction<AuthState>) => {
      state.token = action.payload.token;
      state.user = action.payload.user;
      state.checkoutReference = action.payload.checkoutReference;

      localStorage.setItem("token", JSON.stringify(action.payload.token));
      localStorage.setItem("userInfo", JSON.stringify(action.payload.user));
      localStorage.setItem(
        "checkoutReference",
        JSON.stringify(action.payload.checkoutReference)
      );
    },
  },
});

export const { setCredentials } = slice.actions;
export default slice.reducer;
