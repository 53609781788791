import React from "react";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

export default function SkeletonLoader() {
  return (
    <>
      <div className="container-fluid p-0">
        <div className="row">
          <div className="col-md-12 mb-3 px-2">
           
          <div className={` mb-2`}>
              <Skeleton count={1} width={`70%`} height={80} />
            </div>
            <div className={`text-center mb-2 mt-2`}>
              <Skeleton count={1} width={`100%`} height={180} />
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginBottom:'2rem'
              }}
            >
              <Skeleton count={1} width={130} height={50} />
              <Skeleton count={1} width={130} height={50} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
