import { configureStore } from "@reduxjs/toolkit";

import { api } from "services/index";
import orderReducer from "./slice/order/order.slice";
import authReducer from "./slice/auth/auth.slice";

const reducer = {
  order: orderReducer,
  auth: authReducer,
  [api.reducerPath]: api.reducer,
};

export const store = configureStore({
  reducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(api.middleware),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
