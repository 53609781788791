import {
  CheckoutReferenceOrderResponse,
  ConfirmPaymentRequest,
  ConfirmPaymentResponse,
  PendingOrderResponse,
} from "types";
import { api } from "./index";

export const orderEndpoint = api.injectEndpoints({
  endpoints: (build) => ({
    getPendingOrder: build.mutation<PendingOrderResponse, void>({
      query: () => ({
        url: `/Order/PendingOrder`,
        method: "GET",
      }),
    }),
    getCheckoutReferenceOrder: build.mutation<
      CheckoutReferenceOrderResponse,
      string
    >({
      query: (checkoutReference) => ({
        url: `/Order/CheckoutReference?checkoutReference=${checkoutReference}`,
        method: "GET",
      }),
    }),
    confirmPayment: build.mutation<
      ConfirmPaymentResponse,
      ConfirmPaymentRequest
    >({
      query: (body) => ({
        url: `/Order/Confirm`,
        method: "POST",
        body,
      }),
    }),
    raiseDispute: build.mutation<any, any>({
      query: (body) => ({
        url: `/Order/RaiseDispute`,
        method: "POST",
        body,
      }),
    }),
    getThirdPartyPublicKey: build.mutation({
      query: () => ({
        url: `/Auth/AppKey`,
        method: "GET",
      }),
    }),
  }),
  overrideExisting: true,
});

export const {
  useGetPendingOrderMutation,
  useConfirmPaymentMutation,
  useRaiseDisputeMutation,
  useGetThirdPartyPublicKeyMutation,
  useGetCheckoutReferenceOrderMutation,
} = orderEndpoint;
