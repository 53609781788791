import { useEffect, useState } from "react";
import { getMessageHistory, sendMessage } from "xendbridgechat-sdk";
import useRedux from "hooks/useRedux";
import {
  useGetCheckoutReferenceOrderMutation,
  useGetThirdPartyPublicKeyMutation,
  useRaiseDisputeMutation,
} from "services/order.service";
import ChatLoader from "components/Loader/ChatLoader";
import { capitalizeFirstLetter, formatTime } from "components/utils";
import { useLocation } from "react-router-dom";
import { savePendingOrder } from "store/slice/order/order.slice";

// interface IProps {
//   setModalType: Dispatch<SetStateAction<string>>;
// }

const PaymentMethods = ({ setModalType }) => {
  const { appSelector, dispatch } = useRedux();
  const location = useLocation();

  let reference = new URLSearchParams(location?.search).get("ref") || "";

  const disputeReference = appSelector(
    (state) => state.order.pendingOrder.disputeReference
  );
  const orderReference = appSelector(
    (state) => state.order.pendingOrder.orderReference
  );
  const disputeStatus = appSelector(
    (state) => state.order.pendingOrder.disputeStatus
  );
  const emailAddress = appSelector(
    (state) => state.order.customerInfo.emailAddress
  );

  const [raiseDispute] = useRaiseDisputeMutation();
  const [getThirdPartyPublicKey] = useGetThirdPartyPublicKeyMutation();
  const [publicKey, setPublicKey] = useState("");
  const [chats, getAllChats] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [comment, setComment] = useState("");
  const [base64Attachment, setBase64Attachment] = useState("");
  const [getPendingOrder] = useGetCheckoutReferenceOrderMutation();
  const [getPendingOrderWithoutLoader] = useGetCheckoutReferenceOrderMutation();

  const userPendingOrderWithoutLoader = async () => {
    let resp = await getPendingOrderWithoutLoader(reference).unwrap();
    dispatch(savePendingOrder(resp?.data?.orderDetail));
  };

  useEffect(() => {
    userPendingOrderWithoutLoader();
    const intervalCall = setInterval(() => {
      userPendingOrderWithoutLoader();
    }, 2000); //2 seconds
    return () => {
      clearInterval(intervalCall);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getPublicKey = async () => {
    let resp = await getThirdPartyPublicKey().unwrap();
    setPublicKey(resp?.data?.publicKey);
  };
  useEffect(() => {
    getPublicKey();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getMessages = async () => {
    await getMessageHistory(disputeReference, publicKey, getAllChats);
  };

  const startApp = async () => {
    setIsLoading(true);
    getMessages();
    setIsLoading(false);
  };

  useEffect(() => {
    startApp();

    const intervalCall = setInterval(() => {
      startApp();
    }, 1000); //1 seconds
    return () => {
      clearInterval(intervalCall);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getMessages]);

  const addComment = async (e) => {
    e.preventDefault();
    if (comment === "") {
      setComment("");
      setBase64Attachment("");
    } else if (disputeStatus === "NoDispute") {
      let payload = {
        orderReference,
        remark: comment,
        base64Attachment: base64Attachment,
      };
      let resp = await raiseDispute(payload).unwrap();

      if (resp?.data?.isSuccessful) {
        setComment("");
        setBase64Attachment("");
        let ref = await getPendingOrder(reference).unwrap();
        if (ref?.data?.orderDetail?.disputeReference) {
          await getMessageHistory(
            ref?.data?.orderDetail?.disputeReference,
            publicKey,
            getAllChats
          );
        }
      }
    } else {
      let ChatHubAddCommentModel = {
        EmailAddress: emailAddress,
        Comment: comment,
        SentBy: 2,
        Recipient: 1,
        Base64Attachment: base64Attachment,
        DisputeReference: disputeReference,
        SentAt: new Date().toISOString(),
      };

      await sendMessage(ChatHubAddCommentModel, publicKey, getAllChats);

      setComment("");
      setBase64Attachment("");
    }
  };

  const fileToDataUri = (image) => {
    return new Promise((res) => {
      const reader = new FileReader();
      reader.addEventListener("load", () => {
        res(reader.result);
      });
      reader.readAsDataURL(image);
    });
  };

  const uploadImage = async (e) => {
    if (e.target.files) {
      const newImage = await fileToDataUri(e.target.files[0]);
      setBase64Attachment(newImage);
    }
    e.target.value = "";
  };
  return (
    <div className="h-[100vh] flex items-center justify-center  ">
      <div
        className="bg-white lg:w-[380px] w-[90%] rounded-[12px] lg:px-8 lg:py-8 p-8 relative"
        style={{ boxShadow }}
      >
        <div className="flex gap-5" onClick={() => setModalType("SuccessPage")}>
          <img
            src="images/back-icon.svg"
            alt="back-icon"
            className=" cursor-pointer"
          />{" "}
          <h5 className="font-bold text-[20px] text-center mb-0 font-dm">
            Appeal
          </h5>
        </div>

        <div className="font-dm my-6 border-[1px] rounded-[8px] px-4 py-6 border-[#E6E6E6]">
          <div className="flex justify-end items-center mb-6">
            <img src="images/XendBridge.svg" alt="XendBridge" />{" "}
            <img src="images/circle-star.svg" alt="circle-star" />
          </div>

          <div className="h-[250px] overflow-scroll">
            {isLoading ? (
              <ChatLoader />
            ) : (
              <>
                {chats?.length < 1 ? (
                  <div
                    className={`flex items-start gap-3   mb-4 
                      "justify-start"
                    `}
                  >
                    <img
                      src="images/avatar.svg"
                      className="rounded-full mt-6"
                      alt="avatar"
                      height={30}
                    />
                    <div className={`text-[11px] w-[200px] `}>
                      <p className="mb-1">
                        XendBridge{" "}
                        <span className="ml-3 text-[#7C7C7C]">2 mins ago </span>
                      </p>
                      <div className="bg-[#EEF3FF]  text-[#525252] p-2  rounded-[8px] rounded-tl-none">
                        Kindly let us know if you have an issue with this
                        transaction{" "}
                      </div>
                    </div>
                  </div>
                ) : (
                  <div>
                    {chats?.map((item, i) => (
                      <div
                        className={`flex items-start gap-3   mb-4 ${
                          item?.sentBy !== 2 ? "justify-end" : "justify-start"
                        }`}
                        key={i}
                      >
                        <img
                          src="images/avatar.svg"
                          className="rounded-full mt-6"
                          alt="avatar"
                          height={30}
                        />
                        <div className={`text-[11px] w-[200px] `}>
                          <p className="mb-1">
                            {capitalizeFirstLetter(item?.senderName)}
                            <span className="ml-3 text-[#7C7C7C]">
                              {formatTime(item?.receievedAtTimestamp)}
                            </span>
                          </p>
                          <div className="bg-[#EEF3FF]  text-[#525252] p-2  rounded-[8px] rounded-tl-none">
                            {item?.message}
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                )}
              </>
            )}
          </div>

          <footer className="mt-4 flex gap-2">
            <input
              className="bg-[#F5F7FC] border-[1px] border[#D8E0E7] rounded-[5px] w-full px-3  py-2 mt-1 !outline-none"
              placeholder="Write message here"
              onChange={(e) => setComment(e.target.value)}
              value={comment}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  addComment(e);
                }
              }}
            />
            <input type="file" id="file" hidden onChange={uploadImage} />
            <div className="flex items-center">
              {" "}
              <label htmlFor="file">
                <img
                  src="images/add.svg"
                  className="cursor-pointer"
                  alt="add"
                />
              </label>
            </div>
            <img
              src="images/send.svg"
              className="cursor-pointer"
              alt="send"
              onClick={addComment}
            />
          </footer>

          <div className="relative">
            {base64Attachment && (
              <div className="mt-3  ">
                <img className="h-20" src={base64Attachment} alt="" />
              </div>
            )}
          </div>
        </div>

        <div className="flex justify-center">
          <img src="images/trademark.svg" alt="trademark" />
        </div>
      </div>
    </div>
  );
};

const boxShadow = "0px 4px 20px rgba(0, 0, 0, 0.05)";

export default PaymentMethods;
