import React from "react";
import { formatCurrency, formatWalletAddress } from "components/utils";
import tw from "tailwind-styled-components";
// import { Dispatch, SetStateAction } from "react";
import useRedux from "hooks/useRedux";
import QRCode from "react-qr-code";
import { CopyToClipboard } from "react-copy-to-clipboard";
import toast from "react-hot-toast";
import Countdown from "react-countdown";

// interface IProps {
//   setModalType: Dispatch<SetStateAction<string>>;
// }

const SellOrder = ({ setModalType }) => {
  const { appSelector } = useRedux();
  const pendingOrder = appSelector((state) => state.order.pendingOrder);
  const liquidityProviderPaymentOption = appSelector(
    (state) => state.order.lpInfo
  );
  const copyText = () => {
    toast.success("Copied successfully!");
  };

  return (
    pendingOrder && (
      <>
        {" "}
        <p className="text[#52575C]  text-center mb-1 text-xs font-dm px-8">
          Scan QR code with your DeFi wallet or copy the address.
        </p>
        <div className="h-[1px] my-4 w-[100%] bg-[#E8E8E8]"></div>
        <div className="font-dm">
          <div className="flex justify-center">
            <QRCode
              value={
                liquidityProviderPaymentOption?.orderPaymentMethod
                  ?.walletAddress
              }
              size={90}
            />
          </div>
          <p className="my-4 text-[12px] text-[#52575C] text-center px-10">
            Scan QR code to pay make payment or copy wallet address below
          </p>
          <div className="flex  justify-between items-center mb-6 ">
            <div>
              <Title>Wallet Address</Title>
              <TitleValue>
                {formatWalletAddress(
                  liquidityProviderPaymentOption?.orderPaymentMethod
                    ?.walletAddress
                )}{" "}
                <CopyToClipboard
                  text={
                    liquidityProviderPaymentOption?.orderPaymentMethod
                      ?.walletAddress
                  }
                  onCopy={() => copyText()}
                >
                  <img
                    src="images/Copy.svg"
                    alt="copy"
                    className="cursor-pointer"
                  />
                </CopyToClipboard>
              </TitleValue>
            </div>
            <div>
              <Title>Network</Title>
              <TitleValue>
                {
                  liquidityProviderPaymentOption?.orderPaymentMethod
                    ?.network
                }
              </TitleValue>
            </div>
          </div>
          <div className="bg-[#0747a608] border-[1px] border[#E1E1E1] rounded-[7px] px-3 py-5 mb-4 ">
            <div className={` text-[12px] `}>
              <div className="flex justify-between">
                <div className="">
                  <Title>Amount to sell</Title>
                  <TitleValue>
                    {pendingOrder?.payableAmount}{" "}
                    {pendingOrder?.payInCurrencyCode}
                    <CopyToClipboard
                      text={pendingOrder?.payableAmount}
                      onCopy={() => copyText()}
                    >
                      <img
                        src="images/Copy.svg"
                        alt="copy"
                        className="cursor-pointer"
                      />
                    </CopyToClipboard>
                  </TitleValue>
                </div>
                <div className="">
                  <Title>Amount to receive</Title>
                  <TitleValue>
                    {formatCurrency(pendingOrder?.receivableAmount)}{" "}
                    {pendingOrder?.receiveInCurrencyCode}
                  </TitleValue>
                </div>
              </div>
              {/* <div className="h-[1px] my-3 w-[100%] bg-[#E8E8E8]"></div> */}
              {/* <div className="flex justify-between items-center ">
                <div className="lg:w-[54%] w-[50%]">
                  <Title>Best rate</Title>
                  <TitleValue>1USDT - 1233 NGN</TitleValue>
                </div>
                <div className="lg:w-[46%] w-[50%]">
                  <Title>Trx Fee</Title>
                  <TitleValue>0.05% (122.33 NGN)</TitleValue>
                </div>
              </div> */}
            </div>
          </div>
          <p className="text-[12px] text-[#818181] text-center mb-6">
            Send only {pendingOrder?.payableAmount}
            {pendingOrder?.payInCurrencyCode} to this deposit address above.{" "}
            <br />
            Ensure the network you are sending from is{" "}
            {liquidityProviderPaymentOption?.orderPaymentMethod?.network}.
          </p>

          <div className="flex gap-2 items-center font-bold justify-center  mb-5">
              <img src={"images/timer.svg"} alt="timer" />{" "}
              <Countdown
                date={pendingOrder?.orderExpiryTimestamp}
                renderer={({ minutes, seconds }) => (
                  <span className={`${minutes === 0 && "text-[#ea2d3f]"} `}>
                    {minutes || "00"}:{seconds || "00"}
                  </span>
                )}
              />
            </div>
          <button
            onClick={() => setModalType("ConfirmTransaction")}
            className=" mt-1 mb-8 bg-[#0747A6] text-white py-2 w-full text-[17px] rounded-[25px]"
          >
            I have made the payment
          </button>{" "}
        </div>
      </>
    )
  );
};

const Title = tw.p`text-[13px] text-[#52575C]`;
const TitleValue = tw.p`text-[14px] text-[#25282B] font-bold flex gap-1 items-center`;

export default SellOrder;
