import useRedux from "hooks/useRedux";
import { Dispatch, SetStateAction, useState } from "react";
import toast from "react-hot-toast";
import { useLocation } from "react-router-dom";
import {
  useGenerateOTPWithCheckoutReference_Query,
  useGenerateWithOrderTokenMutation,
  useGenerateWithOtpMutation,
} from "services/config.service";
import { useGetCheckoutReferenceOrderMutation } from "services/order.service";
import { setCredentials } from "store/slice/auth/auth.slice";

interface IProps {
  setModalType: Dispatch<SetStateAction<string>>;
  loginType: string;
  setLoginType: Dispatch<SetStateAction<string>>;
}

const OTPVerification = ({ setModalType, loginType, setLoginType }: IProps) => {
  const { dispatch } = useRedux();

  const location = useLocation();
  const [otpcode, setOtpCode] = useState("");

  let reference: string =
    new URLSearchParams(location?.search).get("ref") || "";

  const [generateWithOrderToken, { isLoading: isLoading_ }] =
    useGenerateWithOrderTokenMutation();
  const [getPendingOrder] = useGetCheckoutReferenceOrderMutation();
  const [generateWithOtp, { isLoading }] = useGenerateWithOtpMutation();
  useGenerateOTPWithCheckoutReference_Query({
    checkoutReference: reference,
  });

  const handleSubmit = async () => {
    if (!otpcode) {
      toast.error(
        `Kindly enter your ${
          loginType === "accessToken" ? "Access code" : "OTP code"
        } `
      );
    } else {
      let payload = {
        checkoutReference: reference,
        otpCode: otpcode,
      };

      let payload_ = {
        checkoutReference: reference,
        orderAccessToken: otpcode,
      };

      try {
        const resp =
          loginType === "accessToken"
            ? await generateWithOrderToken(payload_).unwrap()
            : await generateWithOtp(payload).unwrap();
        if (resp.status === "OK") {
          const { data } = resp;
          dispatch(
            setCredentials({
              token: data?.accessToken,
              user: data,
              checkoutReference: reference,
            })
          );

          let pendingOrder = await getPendingOrder(reference).unwrap();

          if (pendingOrder?.data?.orderDetail) {
            setModalType("OrderDetails");
          } else {
            toast.error("Sorry, the order is no longer available");
          }
        }
      } catch (error: any) {
        toast.error(error?.data?.Message || "Oops, Something went wrong!");
        dispatch(
          setCredentials({
            token: null,
            user: null,
            checkoutReference: null,
          })
        );
      }
    }
  };

  return (
    <div className="h-[100vh] flex items-center justify-center ">
      <div
        className="bg-white lg:w-[380px] w-[90%] rounded-[12px] lg:px-8 lg:py-8 p-8"
        style={{ boxShadow }}
      >
        <div
          className="flex gap-5"
          onClick={() => {
            setLoginType("otp");
            setModalType("LoginOption");
          }}
        >
          <img
            src="images/back-icon.svg"
            alt="back-icon"
            className=" cursor-pointer"
          />{" "}
          <h5 className="font-semibold text-[18px] text-center mb-0 font-mena">
            {loginType === "accessToken" ? "Access Code" : "OTP"} Verification
          </h5>
        </div>

        <p className="text[#52575C] text-center mb-8  mt-5 text-sm px-3 font-dm">
          Please enter{" "}
          {loginType === "accessToken"
            ? "your Access Code"
            : "the One Time Password sent to your email"}{" "}
          to confirm the transaction
        </p>
        <div className="font-dm px-4">
          <label className="text-[#52575C] text-sm ">
            Enter {loginType === "accessToken" ? "Access Code" : "OTP"} here
          </label>
          <input
            className="bg-[#F6F8FF] border-[1px] border[#E5E5E5] rounded-[5px] w-full p-3 mt-1 !outline-none"
            onChange={(e) => setOtpCode(e.target.value)}
          />
          <button
            className=" mt-10 mb-14 bg-[#0747A6] text-white py-2 w-full text-[17px] rounded-[25px]"
            onClick={handleSubmit}
          >
            {isLoading || isLoading_ ? (
              <i className="fa fa-spinner  fa-pulse " aria-hidden="true"></i>
            ) : (
              "Continue"
            )}
          </button>{" "}
        </div>

        <div className="flex justify-center">
          <img src="images/trademark.svg" alt="trademark" />
        </div>
      </div>
    </div>
  );
};

const boxShadow = "0px 4px 20px rgba(0, 0, 0, 0.05)";

export default OTPVerification;
