import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { clientContactResponse, lpInfoResponse, OrderResponse } from "types";

const initialState = {
  pendingOrder: {} as OrderResponse,
  isPendingOrderLoading: true as boolean,
  customerInfo: {} as clientContactResponse,
  lpInfo: {} as lpInfoResponse,
};

const order = createSlice({
  name: "order",
  initialState,
  reducers: {
    savePendingOrder: (state, action: PayloadAction<OrderResponse>) => {
      state.pendingOrder = action.payload;
    },
    saveCustomerInfo: (state, action: PayloadAction<clientContactResponse>) => {
      state.customerInfo = action.payload;
    },
    saveLPInfo: (state, action: PayloadAction<lpInfoResponse>) => {
      state.lpInfo = action.payload;
    },
    isPendingOrderLoading: (state, action: PayloadAction<boolean>) => {
      state.isPendingOrderLoading = action.payload;
    },
  },
});

export const { savePendingOrder, isPendingOrderLoading, saveCustomerInfo,saveLPInfo } =
  order.actions;
export default order.reducer;
