import React from "react";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

export default function ChatLoader() {
  return (
    <>
      <div className="container-fluid p-0">
        <div className="row">
          <div className="col-md-12 mb-3 px-2">
            <div className={` mb-2`}>
              <Skeleton count={1} width={`70%`} height={70} />
            </div>
            <div className={`text-right mb-2`}>
              <Skeleton count={1} width={`70%`} height={70} />
            </div>
            <div className={` mb-2`}>
              <Skeleton count={1} width={`70%`} height={70} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
