import { Dispatch, SetStateAction } from "react";
import BuyOrder from "./BuyOrder";
import SellOrder from "./SellOrder";
import useRedux from "hooks/useRedux";

interface IProps {
  setModalType: Dispatch<SetStateAction<string>>;
}

const MerchantDetails = ({ setModalType }: IProps) => {
  const { appSelector } = useRedux();
  const orderType = appSelector((state) => state.order.pendingOrder.orderType);
  const liquidityProviderPaymentOption = appSelector(
    (state) => state.order.lpInfo
  );
  const SwapCrypto =
    orderType === "Swap" &&
    liquidityProviderPaymentOption?.paymentMethod === "Crypto";
  const SwapFiat =
    orderType === "Swap" &&
    liquidityProviderPaymentOption?.paymentMethod !== "Crypto";

  return (
    <div className="h-[100vh] flex items-center justify-center  ">
      <div
        className="bg-white lg:w-[380px] w-[90%] rounded-[12px] lg:px-8 lg:py-4 p-8 relative"
        style={{ boxShadow }}
      >
        <div
          className="absolute left-5 cursor-pointer"
          onClick={() => setModalType("OrderDetails")}
        >
          <img src="images/back-btn.svg" alt="trademark" />
        </div>
        <h5 className="font-bold text-[20px] text-center mb-1 font-dm">
          Order Details
        </h5>
        {(orderType === "Sell" || SwapCrypto) && (
          <SellOrder setModalType={setModalType} />
        )}
        {(orderType === "Buy" || SwapFiat) && (
          <BuyOrder setModalType={setModalType} />
        )}

        <div className="flex justify-center">
          <img src="images/trademark.svg" alt="trademark" />
        </div>
      </div>
    </div>
  );
};

const boxShadow = "0px 4px 20px rgba(0, 0, 0, 0.05)";

export default MerchantDetails;
