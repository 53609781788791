import { Dispatch, SetStateAction, useEffect } from "react";
import tw from "tailwind-styled-components";
import {
  formatCurrency,
  getP2pOrderStatus,
  getP2pOrderStatusMsg,
} from "components/utils";
import useRedux from "hooks/useRedux";
import { useGetCheckoutReferenceOrderMutation } from "services/order.service";
import {
  isPendingOrderLoading,
  saveCustomerInfo,
  saveLPInfo,
  savePendingOrder,
} from "store/slice/order/order.slice";
import SkeletonLoader from "components/Loader/SkeletonLoader";
import { useLocation } from "react-router-dom";
import { setCredentials } from "store/slice/auth/auth.slice";

interface IProps {
  setModalType: Dispatch<SetStateAction<string>>;
}

const SuccessPage = ({ setModalType }: IProps) => {
  const { dispatch, appSelector } = useRedux();
  const location = useLocation();
  let reference: string =
    new URLSearchParams(location?.search).get("ref") || "";

  const orderType = appSelector(
    (state) => state.order?.pendingOrder?.orderType
  );
  const pendingOrder = appSelector((state) => state.order.pendingOrder);
  const liquidityProviderPaymentOption = appSelector(
    (state) => state.order.lpInfo
  );
  const [getPendingOrder, { isLoading }] =
    useGetCheckoutReferenceOrderMutation();
  const [getPendingOrderWithoutLoader] = useGetCheckoutReferenceOrderMutation();

  const userPendingOrder = async () => {
    let resp = await getPendingOrder(reference).unwrap();
    dispatch(isPendingOrderLoading(isLoading));
    dispatch(savePendingOrder(resp?.data?.orderDetail));
    dispatch(saveCustomerInfo(resp?.data?.clientContact));
    dispatch(saveLPInfo(resp?.data?.liquidityProviderPaymentOption));
  };
  useEffect(() => {
    userPendingOrder();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const userPendingOrderWithoutLoader = async () => {
    let resp = await getPendingOrderWithoutLoader(reference).unwrap();
    dispatch(savePendingOrder(resp?.data?.orderDetail));
    dispatch(saveCustomerInfo(resp?.data?.clientContact));
    dispatch(saveLPInfo(resp?.data?.liquidityProviderPaymentOption));
  };

  useEffect(() => {
    userPendingOrderWithoutLoader();
    const intervalCall = setInterval(() => {
      userPendingOrderWithoutLoader();
    }, 15000); //15 seconds
    return () => {
      clearInterval(intervalCall);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // useEffect(() => {
  //   if (pendingOrder === null) {
  //     setModalType("LoginOption");
  //   }
  // }, [pendingOrder, setModalType]);

  return (
    <div className="h-[100vh] flex items-center justify-center  ">
      <div
        className="bg-white lg:w-[415px] w-[90%] rounded-[12px] lg:px-auto lg:py-6 p-8 relative"
        style={{ boxShadow }}
      >
        {isLoading ? (
          <SkeletonLoader />
        ) : (
          <>
            <div className="flex justify-center mb-2">
              {pendingOrder?.orderStatus === "Cancelled" ? (
                <img
                  src={"images/cross.png"}
                  className="h-[60px]"
                  alt="cancelled"
                />
              ) : (
                <img
                  src={"images/completed.svg"}
                  className="h-[60px]"
                  alt="completed"
                />
              )}
            </div>

            <h5 className="font-bold text-[16px] text-center mb-2 font-dm">
              {getP2pOrderStatus(pendingOrder?.orderStatus, orderType)}
            </h5>

            <p className="text[#52575C] font-[600] text-[31px] text-[#0747A6] text-center my-4">
              {formatCurrency(pendingOrder?.receivableAmount)}
              <span className="text-sm">
                {pendingOrder?.receiveInCurrencyCode}
              </span>{" "}
            </p>

            <p className="text-[#52575C] text-xs px-4 text-center ">
              {getP2pOrderStatusMsg(
                pendingOrder?.orderStatus,
                liquidityProviderPaymentOption?.orderPaymentMethod?.network
              )}
            </p>
           
            <div className="font-dm mt-8 mb-8">
              <div className="bg-[#2042b808] px-4 pt-4 pb-2 rounded-[10px] lg:w-[355px]">
                <h6 className="text-[#525252] text-[15px]">
                  Transaction Details
                </h6>
                <div className="mt-3">
                  <Wrapper>
                    <Title>Payment Method</Title>
                    <TitleValue>
                      {liquidityProviderPaymentOption?.paymentMethod}
                    </TitleValue>
                  </Wrapper>
                  <Wrapper>
                    <Title>Amount Paid</Title>
                    <TitleValue>
                      {formatCurrency(pendingOrder?.payableAmount)}
                      {pendingOrder?.payInCurrencyCode}{" "}
                      {/* {pendingOrder?.customerPayInCurrencyNetwork !==
                        "LOCAL" && (
                        <>({pendingOrder?.customerPayInCurrencyNetwork})</>
                      )} */}
                    </TitleValue>
                  </Wrapper>
                  <Wrapper>
                    <Title>Amount to Receive</Title>
                    <TitleValue>
                      {formatCurrency(pendingOrder?.receivableAmount)}
                      {pendingOrder?.receiveInCurrencyCode}
                      {/* {pendingOrder?.customerReceieveInCurrencyNetwork !==
                        "LOCAL" && (
                        <>({pendingOrder?.customerReceieveInCurrencyNetwork})</>
                      )} */}
                    </TitleValue>
                  </Wrapper>
                  <Wrapper>
                    <Title>Date</Title>
                    <TitleValue>
                      {new Date(
                        pendingOrder?.initiatedAtTimestamp
                      ).toLocaleDateString()}
                      &nbsp;
                      {new Intl.DateTimeFormat("default", {
                        hour12: true,
                        hour: "numeric",
                        minute: "numeric",
                      }).format(pendingOrder?.initiatedAtTimestamp)}
                    </TitleValue>
                  </Wrapper>
                  <Wrapper>
                    <Title>Reference</Title>
                    <TitleValue className="w-[230px] ">
                      {pendingOrder?.orderReference}
                    </TitleValue>
                  </Wrapper>
                </div>
              </div>

              <div className="flex gap-4 mt-6 justify-end">
                <button
                  className="w-[50%]  bg-[#0747A6] text-white py-2 w-full text-[17px] rounded-[25px]"
                  onClick={() => {
                    dispatch(
                      setCredentials({
                        token: null,
                        user: null,
                        checkoutReference: null,
                      })
                    );
                    setModalType("LoginOption");
                  }}
                >
                  Close
                </button>
                <button
                  className="w-[50%]  bg-[#EBEFFF] text-[#2042B8] py-2 w-full text-[17px] rounded-[25px]"
                  onClick={() => setModalType("DisputeChat")}
                >
                  Appeal
                </button>
              </div>
            </div>
          </>
        )}

        <div className="flex justify-center">
          <img src="images/trademark.svg" alt="trademark" />
        </div>
      </div>
    </div>
  );
};

const boxShadow = "0px 4px 20px rgba(0, 0, 0, 0.05)";
const Wrapper = tw.div`flex justify-between items-center flex-wrap mb-2`;
const Title = tw.p`text-[12px] text-[#525252] font-[500]`;
const TitleValue = tw.p`text-[12px] text-[#080A0C] font-[500]`;

export default SuccessPage;
