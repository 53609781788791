import {
  BaseQueryFn,
  createApi,
  FetchArgs,
  fetchBaseQuery,
  FetchBaseQueryError,
} from "@reduxjs/toolkit/query/react";
import { RootState } from "store";
import { setCredentials } from "store/slice/auth/auth.slice";

const baseQuery = fetchBaseQuery({
  baseUrl: process.env.REACT_APP_BASE_URL,
  prepareHeaders: (headers, { getState }) => {
    const token = (getState() as RootState).auth.token;

    token && headers.set("authorization", `Bearer ${token}`);
    headers.set("Content-Type", "application/json");
    headers.set("Accept", "application/json");
    return headers;
  },
});

const baseQueryWithInterceptor: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError
> = async (args, api, extraOptions) => {
  const checkoutReference = (api.getState() as RootState).auth
    .checkoutReference;

  let reference: string =
    new URLSearchParams(window?.location?.search).get("ref") || "";

  if (checkoutReference !== reference) {
    api.dispatch(
      setCredentials({ token: null, user: null, checkoutReference: null })
    );
  }
  let result = await baseQuery(args, api, extraOptions);

  if (result?.error?.status === 401) {
    api.dispatch(
      setCredentials({ token: null, user: null, checkoutReference: null })
    );
  }

  return result;
};

export const api = createApi({
  baseQuery: baseQueryWithInterceptor,
  endpoints: (build) => ({
    // omitted
  }),
});
