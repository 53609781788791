import { Dispatch, SetStateAction, useState } from "react";

interface IProps {
  setModalType: Dispatch<SetStateAction<string>>;
  setLoginType: Dispatch<SetStateAction<string>>;
}

const LoginOption = ({ setModalType, setLoginType }: IProps) => {
  const [activePayment, setActivePayment] = useState(0);

  return (
    <div className="h-[100vh] flex items-center justify-center ">
      <div
        className="bg-white lg:w-[380px] w-[90%] rounded-[12px] lg:px-8 lg:py-8 p-8"
        style={{ boxShadow }}
      >
        <div className="flex justify-center mb-4">
          <img src="images/logo.svg" alt="trademark" />{" "}
        </div>

        <p className="text[#52575C] text-center mb-8 text-sm lg:px-5 px-8 font-dm">
          Checkout in few sec. Choose from available login options below.
        </p>
        <div className="font-dm">
          <div className="flex justify-between items-center mb-8 gap-6">
            {loginOptions.map((item, i) => (
              <div
                className={`p-3 border-[1px] rounded-lg cursor-pointer ${
                  activePayment === i
                    ? " text-[#52575C] border-[#0747A6] "
                    : "text-[#828282]"
                }`}
                key={i}
                onClick={() => {
                  setActivePayment(i);
                  setLoginType(item.value);
                }}
              >
                <div className="flex justify-center">
                  {" "}
                  <img src={item?.icon} className="w-[50px] mb-3" alt="icon" />
                </div>
                <p
                  className={`text-[.8rem] ${
                    activePayment === i && "text-[#0747A6]"
                  }`}
                >
                  {item?.type}
                </p>
              </div>
            ))}
          </div>
          <button
            className=" mt-1 mb-8 bg-[#0747A6] text-white py-2 w-full text-[17px] rounded-[25px]"
            onClick={() => setModalType("OTPVerification")}
          >
            Pay with XendBridge
          </button>{" "}
        </div>

        <div className="flex justify-center">
          <img src="images/trademark.svg" alt="trademark" />
        </div>
      </div>
    </div>
  );
};
const boxShadow = "0px 4px 20px rgba(0, 0, 0, 0.05)";
const loginOptions = [
  {
    type: "One Time Password ",
    icon: "images/password.png",
    value: "otp",
  },
  {
    type: "Order Access Code",
    icon: "images/access-code.png",
    value: "accessToken",
  },
];

export default LoginOption;
