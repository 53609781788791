import { Dispatch, SetStateAction, useState } from "react";
import useRedux from "hooks/useRedux";
import { useConfirmPaymentMutation } from "services/order.service";
import toast from "react-hot-toast";

interface IProps {
  setModalType: Dispatch<SetStateAction<string>>;
}

const ConfirmTransaction = ({ setModalType }: IProps) => {
  const { appSelector } = useRedux();
  const orderReference = appSelector(
    (state) => state.order.pendingOrder.orderReference
  );
  const [confirmPayment, { isLoading }] = useConfirmPaymentMutation();
  const [transactionHash, setTransactionHash] = useState("");

  const handleSubmit = async () => {
    if (!transactionHash) {
      toast.error("Kindly enter your transaction hash");
    } else {
      try {
        let payload = {
          orderReference,
          transactionHash,
        };
        let resp = await confirmPayment(payload).unwrap();

        if (resp?.data) {
          setModalType("SuccessPage");
        }
      } catch (error: any) {
        toast.error(error?.data?.Message || "Oops, Something Went Wrong");
      }
    }
  };

  return (
    <div className="h-[100vh] flex items-center justify-center  ">
      <div
        className="bg-white lg:w-[380px] w-[90%] rounded-[12px] lg:px-12 lg:py-8 p-8 relative"
        style={{ boxShadow }}
      >
        <div
          className="absolute left-5 cursor-pointer"
          onClick={() => setModalType("MerchantDetails")}
        >
          <img src="images/back-btn.svg" alt="trademark" />
        </div>
        <h5 className="font-bold text-[20px] text-center mb-2 font-dm">
          Confirm Transaction
        </h5>

        <p className="text[#52575C]  text-center mb-1 text-[14px] font-dm px-6">
          We need to confirm your transaction on the blockchain this takes few
          minutes.
        </p>

        <div className="font-dm mt-8">
          <label className="text-[#52575C] text-sm ">Enter your Trx Hash</label>
          <input
            className=" border-[1px] border[#E5E5E5] rounded-[5px] w-full p-3 mt-1 !outline-none"
            onChange={(e) => setTransactionHash(e.target.value)}
          />
          <button
            className=" mt-10 mb-14 bg-[#0747A6] text-white py-2 w-full text-[17px] rounded-[25px]"
            onClick={() => handleSubmit()}
          >
            {isLoading ? (
              <i className="fa fa-spinner  fa-pulse " aria-hidden="true"></i>
            ) : (
              "Continue"
            )}
          </button>{" "}
        </div>

        <div className="flex justify-center">
          <img src="images/trademark.svg" alt="trademark" />
        </div>
      </div>
    </div>
  );
};

const boxShadow = "0px 4px 20px rgba(0, 0, 0, 0.05)";

export default ConfirmTransaction;
