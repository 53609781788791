import React from "react";
import { formatCurrency } from "components/utils";
import tw from "tailwind-styled-components";
// import { Dispatch, SetStateAction } from "react";
import useRedux from "hooks/useRedux";
import { useConfirmPaymentMutation } from "services/order.service";
import toast from "react-hot-toast";
import { CopyToClipboard } from "react-copy-to-clipboard";
import Countdown from "react-countdown";

// interface IProps {
//   setModalType: Dispatch<SetStateAction<string>>;
// }

const BuyOrder = ({ setModalType }) => {
  const { appSelector } = useRedux();
  const pendingOrder = appSelector((state) => state.order.pendingOrder);
  const liquidityProviderPaymentOption = appSelector(
    (state) => state.order.lpInfo
  );
  const orderReference = appSelector(
    (state) => state.order.pendingOrder.orderReference
  );
  const [confirmPayment, { isLoading }] = useConfirmPaymentMutation();

  const handleSubmit = async () => {
    let payload = {
      orderReference,
      transactionHash: "",
    };
    let resp = await confirmPayment(payload).unwrap();

    if (resp?.data) {
      setModalType("SuccessPage");
    }
  };

  const copyText = () => {
    toast.success("Copied successfully!");
  };

  return (
    pendingOrder &&
    liquidityProviderPaymentOption && (
      <>
        {" "}
        <p className="text[#52575C]  text-center mb-1 text-xs font-dm px-8">
          Send funds to the account details below.
        </p>
        <div className="flex gap-2 items-center font-bold justify-center mt-3">
          <img src={"images/timer.svg"} alt="timer" />{" "}
          <Countdown
            date={pendingOrder?.orderExpiryTimestamp}
            renderer={({ minutes, seconds }) => (
              <span className={`${minutes === 0 && "text-[#ea2d3f]"} `}>
                {minutes || "00"}:{seconds || "00"}
              </span>
            )}
          />
        </div>
        <div className="font-dm mt-10">
          <div className="flex items-center mb-4 ">
            <div className="w-[50%]">
              <Title>Bank</Title>
              <TitleValue>
                {liquidityProviderPaymentOption?.orderPaymentMethod?.bankName}
              </TitleValue>
            </div>
            <div className="w-[50%]">
              <Title>Account Name</Title>
              <TitleValue>
                {
                  liquidityProviderPaymentOption?.orderPaymentMethod
                    ?.accountName
                }
                <CopyToClipboard
                  text={
                    liquidityProviderPaymentOption?.orderPaymentMethod
                      ?.accountName
                  }
                  onCopy={() => copyText()}
                >
                  <img
                    src="images/Copy.svg"
                    alt="copy"
                    className="cursor-pointer"
                  />
                </CopyToClipboard>
              </TitleValue>
            </div>
          </div>
          <div className="flex items-center mb-8 ">
            <div className="w-[50%]">
              <Title>Account Number</Title>
              <TitleValue>
                {
                  liquidityProviderPaymentOption?.orderPaymentMethod
                    ?.accountNumber
                }
                <CopyToClipboard
                  text={
                    liquidityProviderPaymentOption?.orderPaymentMethod
                      ?.accountNumber
                  }
                  onCopy={() => copyText()}
                >
                  <img
                    src="images/Copy.svg"
                    alt="copy"
                    className="cursor-pointer"
                  />
                </CopyToClipboard>
              </TitleValue>
            </div>
            {/* <div className="w-[50%]">
              <Title>Reference</Title>
              <TitleValue>
                {formatOrderRef(pendingOrder?.orderReference)}
                <CopyToClipboard
                  text={pendingOrder?.orderReference}
                  onCopy={() => copyText()}
                >
                  <img
                    src="images/Copy.svg"
                    alt="copy"
                    className="cursor-pointer"
                  />
                </CopyToClipboard>
              </TitleValue>
            </div> */}
          </div>
          <div className="bg-[#0747a608] border-[1px] border[#E1E1E1] rounded-[7px] px-3 py-4 mb-4 ">
            <div className={` text-[12px] `}>
              <div className="flex justify-between">
                <div>
                  <Title>Amount to send</Title>
                  <TitleValue>
                    {pendingOrder?.payableAmount}&nbsp;
                    {pendingOrder?.payInCurrencyCode}
                    <CopyToClipboard
                      text={pendingOrder?.payableAmount}
                      onCopy={() => copyText()}
                    >
                      <img
                        src="images/Copy.svg"
                        alt="copy"
                        className="cursor-pointer"
                      />
                    </CopyToClipboard>
                  </TitleValue>
                </div>
                <div>
                  <Title>Amount to receive</Title>
                  <TitleValue>
                    {formatCurrency(pendingOrder?.receivableAmount)}
                    &nbsp;
                    {pendingOrder?.receiveInCurrencyCode}
                  </TitleValue>
                </div>
              </div>
              {/* <div className="h-[1px] my-3 w-[100%] bg-[#E8E8E8]"></div> */}
              {/* <div className="flex justify-between items-center ">
                <div className="lg:w-[54%] w-[50%]">
                  <Title>Best rate</Title>
                  <TitleValue>1USDT - 1233 NGN</TitleValue>
                </div>
                <div className="lg:w-[46%] w-[50%]">
                  <Title>Trx Fee</Title>
                  <TitleValue>0.05% (122.33 NGN)</TitleValue>
                </div>
              </div> */}
            </div>
          </div>
          <p className="text-[12px] text-[#818181] mb-6 text-center">
            Make sure to send the right amount of fiat, so the payment doesn’t
            result in a refund.
          </p>
          <button
            onClick={() => handleSubmit()}
            className=" mt-1 mb-8 bg-[#0747A6] text-white py-2 w-full text-[17px] rounded-[25px]"
          >
            {isLoading ? (
              <i className="fa fa-spinner  fa-pulse " aria-hidden="true"></i>
            ) : (
              "I have made the payment"
            )}
          </button>{" "}
        </div>
      </>
    )
  );
};

const Title = tw.p`text-[13px] text-[#52575C]`;
const TitleValue = tw.p`text-[14px] text-[#25282B] font-bold flex gap-1 items-center`;

export default BuyOrder;
