import MerchantDetails from "components/MerchantDetails";
import OTPVerification from "components/OTPVerification";
// import AddPaymentMethod from "components/PaymentMethod/AddPaymentMethod";
// import PaymentMethods from "components/PaymentMethod/PaymentMethods";
import ConfirmTransaction from "components/ConfirmTransaction";
import SuccessPage from "components/SuccessPage";
import DisputeChat from "components/DisputeChat";
import OrderDetails from "components/OrderDetails";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import useRedux from "hooks/useRedux";
import { useGetCheckoutReferenceOrderMutation } from "services/order.service";
import {
  isPendingOrderLoading,
  saveCustomerInfo,
  saveLPInfo,
  savePendingOrder,
} from "store/slice/order/order.slice";
import LoginOption from "components/LoginOption";
import { useLocation } from "react-router-dom";

interface IProps {
  setModalType: Dispatch<SetStateAction<string>>;
  modalType: string;
}

function Index({ modalType, setModalType }: IProps) {
  const location = useLocation();

  let reference: string =
    new URLSearchParams(location?.search).get("ref") || "";
  const { dispatch, appSelector } = useRedux();
  const isLoggedIn = appSelector((state) => state.auth.token);
  const [getPendingOrder, { isLoading }] =
    useGetCheckoutReferenceOrderMutation();
  const [loginType, setLoginType] = useState("otp");

  const userPendingOrder = async () => {
    let resp = await getPendingOrder(reference).unwrap();

    if (resp?.data?.orderDetail) {
      dispatch(isPendingOrderLoading(isLoading));
      dispatch(savePendingOrder(resp?.data?.orderDetail));
      dispatch(saveCustomerInfo(resp?.data?.clientContact));
      dispatch(saveLPInfo(resp?.data?.liquidityProviderPaymentOption));

      if (resp?.data?.orderDetail?.orderStatus !== "Acknowledged") {
        setModalType("SuccessPage");
      } else {
        setModalType("OrderDetails");
      }
    }
  };

  useEffect(() => {
    if (!isLoggedIn) {
      setModalType("LoginOption");
    } else {
      userPendingOrder();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoggedIn, setModalType]);

  return (
    <>
      {modalType === "LoginOption" && (
        <LoginOption setModalType={setModalType} setLoginType={setLoginType} />
      )}

      {modalType === "OTPVerification" && (
        <OTPVerification
          setModalType={setModalType}
          loginType={loginType}
          setLoginType={setLoginType}
        />
      )}

      {modalType === "OrderDetails" && (
        <OrderDetails setModalType={setModalType} />
      )}
      {/* {modalType === "PaymentMethods" && (
        <PaymentMethods setModalType={setModalType} />
      )}
      {modalType === "AddPaymentMethod" && (
        <AddPaymentMethod setModalType={setModalType} />
      )} */}
      {modalType === "MerchantDetails" && (
        <MerchantDetails setModalType={setModalType} />
      )}
      {modalType === "ConfirmTransaction" && (
        <ConfirmTransaction setModalType={setModalType} />
      )}
      {modalType === "SuccessPage" && (
        <SuccessPage setModalType={setModalType} />
      )}
      {modalType === "DisputeChat" && (
        <DisputeChat setModalType={setModalType} />
      )}
    </>
  );
}

export default Index;
