import {
  // cryptoCurrencies,
  // cryptoNetworks,
  formatCurrency,
} from "components/utils";
import { Dispatch, SetStateAction } from "react";
// import Dropdown from "../Input/Dropdown";
import useRedux from "hooks/useRedux";

interface IProps {
  setModalType: Dispatch<SetStateAction<string>>;
}

const OrderDetails = ({ setModalType }: IProps) => {
  // const [networkValue, setNetworkValue] = useState("");
  // const [crytoValue, setCryptoValue] = useState("");

  const { appSelector } = useRedux();
  const orderType = appSelector((state) => state.order.pendingOrder.orderType);
  const pendingOrder = appSelector((state) => state.order.pendingOrder);
  const isLoading = appSelector((state) => state.order.isPendingOrderLoading);

  return (
    pendingOrder && (
      <div className="h-[100vh] flex items-center justify-center ">
        <div
          className="bg-white lg:w-[380px] w-[90%] rounded-[12px] lg:px-12 lg:py-8 p-8"
          style={{ boxShadow }}
        >
          <div className="flex justify-center mb-4">
            <img src="images/logo.svg" alt="trademark" />{" "}
          </div>

          <p className="text[#52575C] text-center mb-8 text-sm lg:px-10 px-8 font-dm">
            Pay with xendbridge, checkout in few sec.
          </p>
          <div className="font-dm">
            <div
              className={` mb-20 border-[1px] border[#E1E1E1] rounded-[7px] p-3 mb-8 text-center 
               
              `}
            >
              {isLoading ? (
                <i
                  className="fa fa-spinner fa-2x py-5 fa-pulse "
                  aria-hidden="true"
                ></i>
              ) : (
                <>
                  <p className="text-sm text-[#52575C] font-[500]">
                    Total amount to {orderType.toLowerCase()}
                  </p>
                  <p className="text-[#0747A6] text-[32px] font-[600] pb-0">
                    {orderType === "Sell" || orderType === "Swap"
                      ? `${formatCurrency(pendingOrder?.payableAmount)} ${
                          pendingOrder?.payInCurrencyCode
                        }`
                      : `${formatCurrency(pendingOrder?.receivableAmount)} ${
                          pendingOrder?.receiveInCurrencyCode
                        }`}
                  </p>
                </>
              )}
            </div>
            {/* {orderType === "Sell" && (
              <>
                {" "}
                <label className="text-[#52575C] text-sm ">
                  Select Network
                </label>
                <Dropdown
                  dropdownValue={networkValue}
                  setDropdownValue={setNetworkValue}
                  dropdown={cryptoNetworks}
                />
                <label className="text-[#52575C] text-sm ">Select Crypto</label>
                <Dropdown
                  dropdownValue={crytoValue}
                  setDropdownValue={setCryptoValue}
                  dropdown={cryptoCurrencies}
                />
              </>
            )} */}
            <button
              className=" mt-1 mb-8 bg-[#0747A6] text-white py-2 w-full text-[17px] rounded-[25px]"
              onClick={() => setModalType("MerchantDetails")}
            >
              Go to Payment Details
            </button>{" "}
          </div>

          <div className="flex justify-center">
            <img src="images/trademark.svg" alt="trademark" />
          </div>
        </div>
      </div>
    )
  );
};

const boxShadow = "0px 4px 20px rgba(0, 0, 0, 0.05)";

export default OrderDetails;
